
    sb-badge > .new-badge {
      padding: 4px 6px;
      white-space: nowrap;

      mat-icon.mat-icon {
        margin: 0px;
      }

      &.badge-sm {
        padding: 4px;
      }
    }
  